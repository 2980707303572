import { inject, Injectable } from '@angular/core';
import { Langauge } from '@enums/language.enum';
import { TranslateService } from '@ngx-translate/core';
import { Translation } from 'primeng/api';
import { BehaviorSubject, Observable } from 'rxjs';
import { PlatformService } from './platform.service';
import { StorageService } from './storage.service';

/**
 * PrimeNG key
 */
export const PRIME_NG_KEY = 'primeng';

/**
 * Application language management service
 */
@Injectable({ providedIn: 'root' })
export class LanguageService {

  /**
   * The translation service used for translating text in the application.
   */
  private readonly translateService = inject(TranslateService);
  /**
   * Service for managing storage operations.
   */
  private readonly storageService = inject(StorageService);
  /**
   * Represents the platform service used by the language service.
   */
  private readonly platform = inject(PlatformService);

  /**
   * Subject to track language changes
   */
  public languageSubject = new BehaviorSubject<string>(this.getInitLanguage());

  /**
   * Observable to expose language changes
   */
  languageChanged$ = this.languageSubject.asObservable();


  /**
   * Get initial language.
   * Set language in local storage
   * @returns language key
   */
  getInitLanguage(): string {
    const language = this.storageService.getLanguage();
    if (language) return language;
    this.storageService.setLanguage(Langauge.PL);
    return Langauge.PL;
  }

  /**
   * Change lang
   */
  useLanguage(language: string): void {
    this.translateService.use(language);
    this.setLangToHtmlTag(language);
    this.storageService.setLanguage(language as Langauge);
    this.languageSubject.next(language);
  }

  /**
   * Returns a stream of translated values of a key (or an array of keys) which updates
   * whenever the language changes.
   * @returns A stream of the translated key, or an object of translated keys
   */
  streamPrimengTranslations(): Observable<Translation> {
    return this.translateService.stream(PRIME_NG_KEY);
  }

  /**
   * Get currently used language
   */
  getCurrentLanguage(): string {
    return this.translateService.currentLang;
  }



  /**
   * Set lang html attribute to html tag
   */
  private setLangToHtmlTag(language: string): void {
    if (this.platform.isPlatformBrowser()) {
      document.getElementsByTagName('html')[0].setAttribute('lang', language);
    }
  }
}
