import { Pipe, PipeTransform } from '@angular/core';
import { LanguageService } from '@services/language.service';

@Pipe({
  name: 'customCurrency',
  standalone: true,
  pure: false,
})
export class customCurrency implements PipeTransform {
  constructor(private languageService: LanguageService) {
  }

  transform(value: string | number | undefined): string {
    if (value === undefined || value === null) {
      return ' ';
    }

    const currentLang = this.languageService.getCurrentLanguage();
    let currencySymbol: string;

    switch (currentLang) {
      case 'pl':
        currencySymbol = 'zł';
        break;
      case 'en':
        currencySymbol = 'PLN';
        break;
      default:
        currencySymbol = 'zł';
        break;
    }

    const numericValue = typeof value === 'number' ? value : parseFloat(value);
    const formattedValue = numericValue.toFixed(2);

    return `${formattedValue} ${currencySymbol}`;
  }
}
